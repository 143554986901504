import { OnApproveActions, OnApproveData } from '@paypal/paypal-js';
import {
	API_ENDPOINTS,
	Button,
	Card,
	CardBody,
	CardHeader,
	Dropdown,
	PageLayout,
	route,
	Modal,
	Variants,
	useDisabledContext,
	StringHelpers,
	customToast,
	useLangContext,
	replaceKeyWithValue,
	ButtonTypes,
	Alert,
	Themes,
	getThemeFromUrl,
	PayPalButton
} from 'carrier-fe';
import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import './training_events_view.css';
import { isPast } from 'date-fns';
import { DropdownItemProps } from 'carrier-fe/lib/components/Controls/Dropdown/DropdownItem/DropdownItem';

interface DataProps {
	data: Record<string, string | JSX.Element | null | undefined>;
}

const date_formatter = new Intl.DateTimeFormat(undefined, {
	dateStyle: 'short',
	timeStyle: 'short',
});

export const Status = {
	ALL_CLOSED: 'ALL_CLOSED',
	JOIN_EVENT_MODAL_OPEN: 'JOIN_EVENT_MODAL_OPEN',
	LEAVE_EVENT_MODAL_OPEN: 'LEAVE_EVENT_MODAL_OPEN',
} as const;

function Data(props: DataProps) {
	const { data } = props;

	const renderData = Object.entries(data).map(([key, value]) => {
		return (
			<div key={key}>
				<b>{StringHelpers.title(key)}</b>
				<div>{!!value ? value : '-'}</div>
			</div>
		);
	});

	return <div className="cq__container">{renderData}</div>;
}

function TrainingView() {
	const { disabled, setDisabled } = useDisabledContext();
	const { id } = useParams();
	const navigate = useNavigate();

	const [status, setStatus] = useState<keyof typeof Status>(
		Status.ALL_CLOSED
	);
	const [trainingEvent, setTrainingEvent] = useState<any>();
	const [loading, setLoading] = useState<boolean>(true);
	const { crud, fields } = useLangContext();
	const [paymentErrors, setPaymentErrors] = useState<
      [{ issue: string; description: string }] | null>(null);

	const currencyFormatter = useMemo(
		() =>
			new Intl.NumberFormat(undefined, {
				style: 'currency',
				currency: 'GBP',
			}),
		[]
	);

	useEffect(() => {
		getData();
	}, [id]);

	const getData = () => {
		if (!id) return;
		setLoading(true);

		axios
			.get(
				route(API_ENDPOINTS.TRAINING.EVENT.VIEW, {
					trainingEvent: String(id),
				})
			)
			.then((res) => setTrainingEvent(res.data.data))
			.catch(console.error)
			.finally(() => setLoading(false));
	};

	const isFree = () => {
		return trainingEvent?.requires_payment == 0 ? true : false;
	};

	const price = !!trainingEvent?.price_pence
		? currencyFormatter.format(trainingEvent.price_pence / 100)
		: StringHelpers.title(fields?.free || 'Free');

	const eventData = {
		[fields.name || 'Name']: StringHelpers.title(
			trainingEvent?.name || '-'
		),
		[fields.description || 'Description']: StringHelpers.title(
			trainingEvent?.description || '-'
		),
		[fields.capacity || 'Capacity']: trainingEvent?.event_capacity || '-',
		[fields.price || 'Price']: price,
		[fields.course || 'Course']: (
			<Link to={`/training/course/${trainingEvent?.training_course?.id}`}>
				{StringHelpers.title(
					trainingEvent?.training_course?.name || '-'
				)}
			</Link>
		),
		[fields.event_at || 'Date']: trainingEvent?.event_at
			? date_formatter.format(new Date(trainingEvent.event_at))
			: '-',
	};

	const venueData = {
		[fields.name || 'Name']: StringHelpers.title(
			trainingEvent?.training_venue?.name || '-'
		),
		[fields.capacity || 'Capacity']:
			trainingEvent?.training_venue?.capacity || '-',
		[fields.address_line_1 || 'Address Line 1']: StringHelpers.title(
			trainingEvent?.training_venue?.address?.address_line_1 || '-'
		),
		[fields.address_line_2 || 'Address Line 2']: StringHelpers.title(
			trainingEvent?.training_venue?.address?.address_line_2 || '-'
		),
		[fields.address_line_3 || 'Address Line 3']: StringHelpers.title(
			trainingEvent?.training_venue?.address?.address_line_3 || '-'
		),
		[fields.town_city || 'Town / City']: StringHelpers.title(
			trainingEvent?.training_venue?.address?.town_city || '-'
		),
		[fields.state_county || 'State / County']: StringHelpers.title(
			trainingEvent?.training_venue?.address?.state_county || '-'
		),
		[fields.postcode_zipcode || 'Postcode / Zipcode']: StringHelpers.upper(
			trainingEvent?.training_venue?.address?.postcode_zipcode || '-'
		),
		[fields.country || 'Country']: StringHelpers.title(
			trainingEvent?.training_venue?.address?.country || '-'
		),
	};

	const getActions = (): DropdownItemProps[] => {
		if (
			!trainingEvent ||
			isPast(new Date(trainingEvent.event_at).toISOString())
		)
			return [];

		let actions: DropdownItemProps[] = [];

		if (!trainingEvent?.joined && trainingEvent?.can_be_booked) {
			actions.push({
				label: crud?.buttons?.join?.default || 'Join',
				onClick: () => setStatus(Status.JOIN_EVENT_MODAL_OPEN),
			});
		}

		if (trainingEvent?.joined) {
			actions.push({
				label: crud?.buttons?.leave?.default || 'Leave',
				onClick: () => setStatus(Status.LEAVE_EVENT_MODAL_OPEN),
			});
		}

		return actions;
	};

	const gotoMap = () =>
		window.open(trainingEvent?.training_venue.map_url, '_blank');

	 const proceedToPay = async () => {
		try {
		   const response = await axios.post(
			  route(API_ENDPOINTS.TRAINING.EVENT.INVITE.STORE, {
				trainingEvent: String(id),
			  }),
		   );
		
		   return response.data.data.order_id;
		} catch (err: any) {
			customToast({
				variant: Variants.Danger,
				title:
					err?.response?.data?.message ||
					'Something went wrong...',
			})
			setPaymentErrors(err?.response?.data?.data);
		}
	 };
	  
	const captureThePayement = async (data: OnApproveData, actions: OnApproveActions) => {
		setPaymentErrors(null);
		const orderId = data.orderID;
		try {
			const response = await axios.post(
				route(API_ENDPOINTS.TRAINING.EVENT.INVITE.CAPTURE_PAYMENT,
				{
					trainingEvent: String(id),
				}),
				{
					order_id: orderId,
				}
			);

			customToast({
				title: response?.data?.message,
				variant: Variants.Success,
			});
			getData();
			setDisabled(false);
			setStatus(Status.ALL_CLOSED)
		} catch (err: any) {
			customToast({
				variant: Variants.Danger,
				title:
					err?.response?.data?.message ||
					'Something went wrong how...',
			})
			setPaymentErrors(err?.response?.data?.data);
		}
	};

	const confirmJoin = () => {
		if (!id) return;
		setDisabled(true);

		if (!isFree()) {
			alert('Not implemented yet')
			setDisabled(false);
			setStatus(Status.ALL_CLOSED);
			return ;
		} 

		axios
			.post(
				route(API_ENDPOINTS.TRAINING.EVENT.INVITE.STORE, {
					trainingEvent: id,
				})
			)
			.then((res) => {
				customToast({
					variant: Variants.Success,
					title: res?.data?.message || 'Success',
				});
				setTrainingEvent((e: any) => {
					if (!e) return;
					e.joined = new Date().toISOString();
					return e;
				});
				setStatus(Status.ALL_CLOSED);
			})
			.catch((err) => (
				customToast({
					variant: Variants.Danger,
					title:
						err?.response?.data?.message ||
						'Something went wrong...',
				})
			)
			)
			.finally(() => {
				getData();
				setDisabled(false);
			});
	};

	const confirmLeave = () => {
		if (!id || !trainingEvent?.invite) return;
		setDisabled(true);

		axios
			.delete(
				route(API_ENDPOINTS.TRAINING.EVENT.INVITE.DELETE, {
					trainingEvent: id,
					trainingEventInviteId: trainingEvent?.invite?.id,
				})
			)
			.then((res) => {
				customToast({
					variant: Variants.Success,
					title: res?.data?.message || 'Success',
				});
				setTrainingEvent((e: any) => {
					if (!e) return;
					e.joined = undefined;
					return e;
				});
				setStatus(Status.ALL_CLOSED);
			})
			.catch((err) =>
				customToast({
					variant: Variants.Danger,
					title:
						err?.response?.data?.message ||
						'Something went wrong...',
				})
			)
			.finally(() => {
				getData();
				setDisabled(false);
			});
	};

	const title = loading
		? ''
		: StringHelpers.title(trainingEvent?.name || 'Training Event View');

	let actions = getActions();

	return (
		<>
			<PageLayout
				title={title}
				loading={loading}
			>
				{!trainingEvent ? (
					<p>
						{StringHelpers.title(
							fields.load_page ||
								'Could not load page, please refresh and try again.'
						)}
					</p>
				) : (
					<>
						<Button
							label={crud?.buttons?.back?.default || 'Back'}
							type={ButtonTypes.Outline}
							variant={Variants.Dark}
							className={'mb-4'}
							onClick={() => navigate(-1)}
						/>
						{trainingEvent?.invite?.requested_cancellation_at && (
							<Alert className="mb-4">
								<p className="mb-0">
									{fields?.requested_cancellation ||
										'You have requested to leave this event.'}
								</p>
							</Alert>
						)}
						<div className="cq__container-parent">
							<Card>
								<CardHeader
									leftSlot={
										<h3>
											{replaceKeyWithValue(
												':model ' +
													crud?.sub_titles?.details ||
													'Details',
												'model',
												crud?.models?.training_event ||
													'Training Event'
											)}
										</h3>
									}
									rightSlot={
										actions.length > 0 && (
											<Dropdown
												label={StringHelpers.title(
													crud?.buttons?.actions
														?.default || 'Actions'
												)}
												items={actions}
												type={ButtonTypes.Solid}
												variant={Variants.Dark}
												disabled={disabled}
											/>
										)
									}
								/>
								<CardBody>
									<Data data={eventData} />
								</CardBody>
							</Card>
						</div>

						<div className="cq__container-parent">
							<Card>
								<CardHeader
									leftSlot={
										<h3>
											{replaceKeyWithValue(
												':model ' +
													crud?.sub_titles?.details ||
													'Details',
												'model',
												crud?.models?.training_venue ||
													'Training Venue'
											)}
										</h3>
									}
									rightSlot={
										<Button
											label={
												crud?.buttons?.map?.default ||
												'Open Map'
											}
											variant={Variants.Dark}
											onClick={gotoMap}
										/>
									}
								/>
								<CardBody>
									<Data data={venueData} />
								</CardBody>
							</Card>
						</div>
					</>
				)}
			</PageLayout>

			<Modal
				open={status === Status.JOIN_EVENT_MODAL_OPEN}
				onClose={() => setStatus(Status.ALL_CLOSED)}
				title={replaceKeyWithValue(
					crud?.modals?.join?.title || 'Join :name',
					'name',
					crud?.models?.training_event || 'Training Event'
				)}
				confirmText={
					disabled
						? crud?.buttons?.join?.submitting || 'Joining...'
						: crud?.buttons?.join?.default || 'Join'
				}
				onConfirm={confirmJoin}
				overriddenConfirmButton={
					!isFree() && getThemeFromUrl() === Themes.Viessmann
						? (
							<PayPalButton onApprove={captureThePayement}
								createOrder={proceedToPay} />
						) : undefined
				}
				disabled={disabled}
				variant={Variants.Primary}
			>
				<div className="w-full cq__container-parent">
					<p className="mb-4">
						{replaceKeyWithValue(
							crud?.modals?.join?.description?.[0] ||
								'Are you sure you want to join the following :name',
							'name',
							crud?.models?.training_event || 'Training Event'
						)}
					</p>
					<Data data={eventData} />
				</div>
				{paymentErrors && (
                        <Alert className={'mt-4'} variant={Variants.Danger}>
                           {paymentErrors?.map((error, index) => (
                              <span>{error.description}</span>
                           ))}
                        </Alert>
                     )}
			</Modal>

			<Modal
				open={status === Status.LEAVE_EVENT_MODAL_OPEN}
				onClose={() => setStatus(Status.ALL_CLOSED)}
				title={replaceKeyWithValue(
					crud?.modals?.leave?.title || 'Leave :name',
					'name',
					crud?.models?.training_event || 'Training Event'
				)}
				confirmText={
					disabled
						? crud?.buttons?.leave?.submitting || 'Leaving...'
						: crud?.buttons?.leave?.default || 'Leave'
				}
				onConfirm={confirmLeave}
				disabled={disabled}
				variant={Variants.Danger}
			>
				<div className="w-full cq__container-parent">
					<p className="mb-4">
						{replaceKeyWithValue(
							crud?.modals?.leave?.description[0] ||
								'Are you sure you want to leave the following :name',
							'name',
							crud?.models?.training_event || 'Training Event'
						)}
					</p>
					<Data data={eventData} />
				</div>
			</Modal>
		</>
	);
}

export default TrainingView;
