import {
  Card,
  CardBody,
  PageLayout,
  Icon,
  Icons,
  useLangContext,
  CardHeader,
  Button,
  StringHelpers,
  Variants,
  route,
  API_ENDPOINTS,
  Spinner,
  Permissions,
  useAuthorisationContext,
  useDisabledContext,
  Sizes,
  Badge,
  Themes,
  checkTheme,
} from 'carrier-fe';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CourseType } from '../../types/course';

const DEBOUNCE_TIME = 1000;

const PAGE_SIZE = 50;
const MAX_LENGTH = 50;

interface GridProps<T> {
  endpoint: string;
  renderItem: (item: T) => ReactNode;
  searchable?: boolean;
  pagination?: boolean;
}

interface ApiPaginatedListResponse<R> {
  data: {
    rows: R[];
    pagination: {
      pages: {
        current: number;
        total: number;
      };
      count: {
        current: number;
        total: number;
      };
    };
  };
}

function Grid<T extends CourseType>(props: GridProps<T>) {
  const { endpoint, renderItem, searchable, pagination } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const { crud } = useLangContext();

  const [items, setItems] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { disabled } = useDisabledContext();

  const totalPages = useRef<number>(1);

  const fetch = () => {
    if (!loading) setLoading(true);

    axios
      .get<ApiPaginatedListResponse<T>>(
        endpoint + '?' + searchParams.toString()
      )
      .then((res) => {
        const { rows, pagination } = res.data.data;
        const { total, current } = pagination.pages;

        totalPages.current = total;
        setCurrentPage(current);
        setItems(rows);
      })
      .catch(console.error)
      .finally(reset);
  };

  const reset = () => {
    setLoading(false);
  };

  const renderGrid = () => {
    if (items.length === 0) return <p>No records</p>;

    return (
      <div className="row row-cols-1 row-cols-sm-2 row-cols-xxl-3 gy-4">
        {items.map((item) => {
          return renderItem(item);
        })}
      </div>
    );
  };

  const previousPage = () => {
    if (currentPage === 1) return;
    setCurrentPage((e) => e - 1);
  };

  const nextPage = () => {
    if (currentPage === totalPages.current) return;
    setCurrentPage((e) => e + 1);
  };

  useEffect(() => {
    const params: Record<string, string> = {};

    params['format'] = 'flat';
    params['page[size]'] = String(PAGE_SIZE);

    if (search && search.length > 0) params['filter[search]'] = search;
    if (currentPage) params['page[number]'] = String(currentPage);

    setSearchParams(params);
  }, [search, currentPage]);

  useEffect(() => {
    if (!endpoint) return;

    const debounce = setTimeout(() => fetch(), DEBOUNCE_TIME);
    return () => clearTimeout(debounce);
  }, [endpoint, searchParams.toString()]);

  return (
    <Card>
      <CardHeader
        leftSlot={
          searchable && (
            <div className={'d-flex flex-column align-items-start'}>
              <label
                htmlFor="search"
                className="visually-hidden"
              >
                {crud?.pages.index.search || 'Search'}
              </label>
              <div className="position-relative">
                <div className="position-absolute d-flex h-100 align-items-center ps-2 pe-none">
                  <Icon
                    icon={Icons.SEARCH}
                    style={{ color: '#0d6efd' }}
                  />
                </div>
                <input
                  type="search"
                  id="search"
                  name="search"
                  value={search || ''}
                  onChange={(e) =>
                    setSearch(e.target.value || '')
                  }
                  className="form-control ps-5 py-2"
                  placeholder={
                    crud?.pages.index.search || 'Search'
                  }
                  disabled={disabled}
                />
              </div>
            </div>
          )
        }
      />
      <CardBody>
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        ) : (
          renderGrid()
        )}
        {pagination && (
          <div className="d-flex flex-row-reverse gap-3 mt-4">
            <Button
              label={crud?.buttons?.next?.default || 'Next Page'}
              onClick={nextPage}
              variant={Variants.Light}
              size={Sizes.Small}
              disabled={currentPage === totalPages.current}
            />
            <Button
              label={
                crud?.buttons?.previous?.default ||
                'Previous Page'
              }
              onClick={previousPage}
              variant={Variants.Light}
              size={Sizes.Small}
              disabled={currentPage === 1}
            />
          </div>
        )}
      </CardBody>
    </Card>
  );
}

function TrainingCourses() {
  const navigate = useNavigate();
  const { crud, fields } = useLangContext();

  const renderItem = (item: CourseType) => {
    const gotoEvent = () => {
      if (!item.permissions.view) return;

      navigate(`/training/course/${item.id}`);
    };

    const getVariant = (status: string) => {
      switch (status) {
        case 'completed':
          return Variants.Success;
        case 'expiring':
          return Variants.Danger;
        case 'expired':
          return Variants.Danger;
        case 'abandoned':
        case 'in progress':
        default:
          return Variants.Warning;
      }
    };

    return (
      <div
        className="col"
        key={item.id}
        onClick={gotoEvent}
        style={{ cursor: 'pointer' }}
      >
        <Card
          className={'h-100'}
          noMargin
        >
          {item.image && (
            <img
              src={item.image.url}
              className="object-fit-cover"
              style={{ maxHeight: '300px' }}
            />
          )}
          <CardBody>
            <h4 className="fw-bolder mb-2">{item.name}</h4>
            <div className="d-flex gap-2 flex-wrap mb-4">
              {item.user_status.length > 1 && (
                <Badge
                  label={StringHelpers.title(
                    item.user_status
                  )}
                  variant={getVariant(item.user_status)}
                />
              )}
              {item.category && (
                <Badge
                  label={StringHelpers.title(item.category)}
                  variant={Variants.Info}
                />
              )}
              {item.requires_in_person_event === '1' && (
                <Badge
                  label={StringHelpers.title(
                    fields?.requires_in_person_event ||
                    'requires in person event'
                  )}
                  variant={Variants.Dark}
                />
              )}
            </div>
            {item.description && checkTheme(Themes.Toshiba) && (
              <p className="mb-0">
                {StringHelpers.limit(item.description, MAX_LENGTH)}
              </p>
            )}
          </CardBody>
        </Card>
      </div>
    );
  };

  return (
    <PageLayout
      title={crud?.models?.training_courses || 'Training Courses'}
    >
      <Grid<CourseType>
        endpoint={route(API_ENDPOINTS.TRAINING.COURSE.INDEX)}
        renderItem={renderItem}
        searchable
        pagination
      />
    </PageLayout>
  );
}

export default TrainingCourses;
