import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import Root from '../pages/root';
import Dashboard from '../pages/dashboard';
import {
	AuthenticatedLayout,
	ErrorPage,
	Permissions,
	Platforms,
	ProtectedPage,
} from 'carrier-fe';
import Test from '../pages/test';
import TrainingEvents from '../pages/training_events';
import TrainingView from '../pages/training_events/view';
import TrainingCourses from '../pages/course';
import TrainingCourseView from '../pages/course/view';
import TrainingCourseProgress from '../pages/course/progress';
import QuizWrapper from '../pages/quiz/wrapper';
import QuizSplash from '../pages/quiz/splash';
import TrainingResults from '../pages/result';
import TrainingResultView from '../pages/result/view';

function Router() {
	return (
		<BrowserRouter>
			<Routes>
				{/* TODO - REMOVE TEST PAGE */}
				<Route
					index
					path="/test"
					element={<Test />}
				/>

				<Route
					index
					path="/"
					element={<Root />}
				/>

				{/*
				 * TODO - FOLLOWING ROUTES NEED TO BE AUTH PROTECTED
				 *      - IS A TOKEN SET IN THE COOKIE?
				 *      - IS THAT TOKEN VALID (CHECK VIA THE SERVER?
				 *      - IF NOT -> LOGOUT AND REDIRECT TO LOGIN
				 */}
				<Route
					path="/"
					element={
						<AuthenticatedLayout platform={Platforms.TRAINING} />
					}
				>
					<Route
						index
						path="/dashboard"
						element={<Dashboard />}
					/>
					<Route
						index
						path="/training/event"
						element={
							<ProtectedPage
								requiredPermissions={
									Permissions.TRAINING_EVENT_INDEX
								}
							>
								<TrainingEvents />
							</ProtectedPage>
						}
					/>
					<Route
						index
						path="/training/event/:id"
						element={
							<ProtectedPage
								requiredPermissions={
									Permissions.TRAINING_EVENT_SHOW
								}
							>
								<TrainingView />
							</ProtectedPage>
						}
					/>

					<Route
						index
						path="/training/course"
						element={<TrainingCourses />}
					/>
					<Route
						index
						path="/training/course/:id"
						element={
							<ProtectedPage
								requiredPermissions={
									Permissions.TRAINING_COURSE_SHOW
								}
							>
								<TrainingCourseView />
							</ProtectedPage>
						}
					/>
					<Route
						index
						path="/training/course/:id/progress"
						element={
							<ProtectedPage
								requiredPermissions={
									Permissions.TRAINING_COURSE_SHOW
								}
							>
								<TrainingCourseProgress />
							</ProtectedPage>
						}
					/>

					<Route
						path="/training/course/:id/quiz/:quiz_id/session/:quiz_session_id"
						element={
							<ProtectedPage
								requiredPermissions={
									Permissions.TRAINING_QUIZ_DO
								}
							>
								<QuizWrapper />
							</ProtectedPage>
						}
					/>
					<Route
						path="/training/course/:id/quiz/:quiz_id"
						element={
							<ProtectedPage
								requiredPermissions={
									Permissions.TRAINING_QUIZ_DO
								}
							>
								<QuizSplash />
							</ProtectedPage>
						}
					/>

					<Route
						index
						path="/training/result"
						element={<TrainingResults />}
					/>

					<Route
						path="/training/result/:id"
						element={<TrainingResultView />}
					/>
				</Route>

				{/* TODO - MAKE A PRETTY PAGE IN THE COMPONENT LIBRARY */}
				<Route
					path="*"
					element={<ErrorPage />}
				/>
			</Routes>
		</BrowserRouter>
	);
}

export default Router;
