import axios from 'axios';
import {
	API_ENDPOINTS,
	route,
	ThemeLogo,
	StringHelpers,
	Card,
	CardBody,
	CardHeader,
	useDisabledContext,
	Button,
	WidthConstrainedContainer,
	BottomAnchoredControls,
	useLangContext,
	Variants,
	ButtonTypes,
	getThemeFromUrl,
	Themes,
	SubTitle,
	Alert,
} from 'carrier-fe';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useFullscreen from '../useFullscreen';

interface QuizInterface {
	id: string;
	name: string;
	description: string;
	attempts_before_lockout: number;
	no_of_questions: number;
	passed: boolean;
	no_of_failed_attempts: number;
}

const MAX_WIDTH = 600;

function QuizSplash() {
	const { id, quiz_id } = useParams();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [quizDetails, setQuiz] = useState<QuizInterface>();
	const { disabled } = useDisabledContext();
	const { quiz, crud, fields } = useLangContext();
	const { openFullscreen } = useFullscreen();

	const maxAttemptsReached =
		quizDetails?.no_of_failed_attempts ===
		quizDetails?.attempts_before_lockout;

	const getInfo = () => {
		if (isLoading) return;
		setIsLoading(true);

		axios
			.get(
				route(API_ENDPOINTS.TRAINING.QUIZ.SHOW, {
					trainingCourse: String(id),
					quizId: String(quiz_id),
				})
			)
			.then((res) => {
				setQuiz(res.data.data);
			})
			.catch(console.error)
			.finally(() => setIsLoading(false));
	};

	const createSession = () => {
		if (isLoading) return;
		setIsLoading(true);

		axios
			.post(
				route(API_ENDPOINTS.TRAINING.QUIZ.SESSION.START, {
					trainingCourse: String(id),
					quizId: String(quiz_id),
				})
			)
			.then((res) => {
				openFullscreen();
				navigate(
					`/training/course/${id}/quiz/${quiz_id}/session/${res.data.data.id}`
				);
			})
			.catch(console.error)
			.finally(() => setIsLoading(false));
	};

	const getHeight = (): string => {
		switch (getThemeFromUrl()) {
			case Themes.Viessmann:
				return '80px';
			default:
				return '60px';
		}
	};

	useEffect(() => {
		getInfo();
	}, [quiz_id]);

	return (
		<div className="dashboard--wrapper d-flex vh-100">
			<div className="dashboard--page--wrapper w-100 d-flex flex-column">
				<div className="dashboard--header">
					<div className="d-flex align-items-center border-bottom py-3 px-4 bg-white gap-4">
						<ThemeLogo
							theme={getThemeFromUrl()}
							style={{ height: getHeight() }}
						/>
						<h1 className="d-none d-lg-block">
							{StringHelpers.title(
								quizDetails?.name || 'Quiz name'
							)}
						</h1>
						<div className="ms-auto">
							<Button
								label={StringHelpers.title(
									quiz?.exit || 'exit'
								)}
								onClick={() =>
									navigate(`/training/course/${id}/progress`)
								}
								disabled={disabled}
								variant={Variants.Dark}
								type={ButtonTypes.Outline}
							/>
						</div>
					</div>
					<div className="d-block d-lg-none bg-white p-4">
						<h1 className="mb-0">
							{StringHelpers.title(
								quizDetails?.name || 'Quiz name'
							)}
						</h1>
					</div>
				</div>

				<div className="dashboard--child--wrapper">
					<WidthConstrainedContainer maxWidth={MAX_WIDTH}>
						<Card>
							<CardBody>
								<SubTitle
									title={StringHelpers.title(
										crud?.sub_titles?.details || 'Details'
									)}
									className="mb-3"
									style={{ color: '#464C5E' }}
								/>

								<p>
									<strong>
										{StringHelpers.title(
											fields?.description || 'Description'
										)}
									</strong>
									<br />
									{quizDetails?.description || '-'}
								</p>

								<p>
									<strong>
										{StringHelpers.title(
											quiz?.current_attempts || 'Attempts'
										)}
									</strong>
									<br />
									{quizDetails?.no_of_failed_attempts || 0}
								</p>

								{quizDetails?.attempts_before_lockout && (
									<p>
										<strong>
											{StringHelpers.title(
												quiz?.attempts_before_lockout ||
													'Attempts before lockout'
											)}
										</strong>
										<br />
										{quizDetails.attempts_before_lockout}
									</p>
								)}

								<Alert>
									{quiz?.leaving_disclaimer ||
										'If you leave the quiz, your attempt will be marked as a failure.'}
								</Alert>

								{maxAttemptsReached && (
									<Alert className="mt-4">
										{quiz?.failure_disclaimer ||
											'You have reached the maximum amount of attempts, please contact support.'}
									</Alert>
								)}
							</CardBody>
						</Card>
					</WidthConstrainedContainer>
					<BottomAnchoredControls>
						{!quizDetails?.passed && !maxAttemptsReached && (
							<Button
								label={StringHelpers.title(
									quiz?.start || 'start'
								)}
								onClick={createSession}
								disabled={disabled}
							/>
						)}
					</BottomAnchoredControls>
				</div>
			</div>
		</div>
	);
}

export default QuizSplash;
